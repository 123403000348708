var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"height":"48px"}},[_c('v-toolbar-title',[_vm._v("Liste des documents")]),_c('v-spacer')],1),_c('v-text-field',{staticClass:"ma-4 pa-0",attrs:{"append-icon":"mdi-magnify","label":"Recherche","solo":"","single-line":"","clearable":"","hide-details":""},on:{"blur":function($event){return _vm.saveSearchToCache()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card',{staticClass:"ma-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"page":_vm.pagination.page,"items-per-page":_vm.pagination.itemsPerPage,"footer-props.items-per-page-text":"Lignes par page","hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"page-count":function($event){_vm.pagination.pageCount = $event}},scopedSlots:_vm._u([{key:"header.doc_typ_id",fn:function(ref){
var header = ref.header;
return [_c('v-menu',{staticStyle:{"z-index":"200"},attrs:{"offset-y":"","rounded":"","close-on-content-click":false,"max-height":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":(_vm.headFilter.doc_typ_id.length) ? 'green' : 'grey'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-filter")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.getCacheType('DOC')),function(item,index){return _c('v-list-item',{key:index,on:{"mousedown":function($event){$event.preventDefault();}}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"primary","value":index,"hide-details":""},on:{"click":function($event){return _vm.updateHeaderFilter('doc_typ_id', item.typ_id)}}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.getCacheType('DOC', item.typ_id).typ_name))])],1)],1)}),1)],1),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"item.doc_sta_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":"","label":"","color":_vm.getCacheState('GEN', item.doc_sta_id).typ_color}},[_vm._v(_vm._s(_vm.getCacheState('GEN', item.doc_sta_id).sta_label))])]}},{key:"item.doc_typ_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":"","label":"","color":_vm.getCacheType('DOC', item.doc_typ_id).typ_color}},[_vm._v(_vm._s(_vm.getCacheType('DOC', item.doc_typ_id).typ_name))])]}},{key:"item.doc_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":"","title":item.trs_coddt},on:{"click":function($event){return _vm.downloadDocument(item.doc_id)}}},[_c('v-icon',{attrs:{"left":"","color":"grey"}},[_vm._v("mdi-file-download")])],1)]}},{key:"item.doc_created_at",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(_vm._s(_vm.locDateFormat.formatLocale( item.doc_created_at, 'fr' )))])]}},{key:"item.trs_coddt",fn:function(ref){
var item = ref.item;
return [(item.doc_table_id == 'trs')?_c('v-btn',{attrs:{"href":'#/documents/site/' + item.doc_xxx_id,"small":"","rounded":"","outlined":"","color":"primary","dark":""}},[_vm._v(" "+_vm._s((!item.trs_coddt || !item.trs_coddt.length) ? item.trs_mc2_code : item.trs_coddt)+" ")]):_vm._e()]}}],null,true)},[_c('template',{slot:"no-data"},[_c('v-alert',{staticClass:"mt-2",attrs:{"value":true,"color":"info","icon":"mdi-information-outline"}},[_vm._v("Aucun document")])],1)],2)],1),_c('v-card-actions',{},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.pagination.totalFiltered),expression:"pagination.totalFiltered"}]},[_vm._v(_vm._s(_vm.pagination.totalFiltered)+" sur ")]),_c('span',[_vm._v(_vm._s(_vm.pagination.totalItems)+" résultats")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-text-field',{ref:"pageinput",attrs:{"outlined":"","dense":"","label":"Page"},on:{"focus":function($event){return $event.target.select()}},nativeOn:{"input":function($event){return _vm.changePage($event)}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", _vm._n($$v))},expression:"pagination.page"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('v-pagination',{attrs:{"length":_vm.pagination.pageCount,"total-visible":8},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }