<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Liste des documents</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn rounded to="/site/ajouter" color="success" disabled depressed class="white--text">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Ajouter</span>
        </v-btn> -->
        <!-- <v-switch class="mt-4" v-model="genFilter" label="Filtre général"></v-switch> -->
    </v-toolbar>
    <v-text-field append-icon="mdi-magnify" label="Recherche"
        solo single-line clearable hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" v-bind:items="items" v-bind:search="search"
        :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pagination.pageCount = $event"
        footer-props.items-per-page-text="Lignes par page" hide-default-footer>
        <template v-slot:[`header.doc_typ_id`]="{ header }">
            <v-menu offset-y rounded :close-on-content-click="false" max-height="300" style="z-index:200;">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" :color="(headFilter.doc_typ_id.length) ? 'green' : 'grey'"><v-icon small>mdi-filter</v-icon></v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in getCacheType('DOC')" :key="index" @mousedown.prevent>
                        <v-list-item-action><v-checkbox color="primary" :value="index" @click="updateHeaderFilter('doc_typ_id', item.typ_id)" hide-details></v-checkbox></v-list-item-action>
                        <v-list-item-content>
                        <v-list-item-title>
                            {{ getCacheType('DOC', item.typ_id).typ_name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu> 
            {{ header.text }}
        </template>

        <template v-slot:[`item.doc_sta_id`]="{ item }" :color="( item.doc_sta_id == 'GEN01' ) ? 'green' : 'grey'">
            <v-chip small outlined label :color="getCacheState('GEN', item.doc_sta_id).typ_color">{{ getCacheState('GEN', item.doc_sta_id).sta_label }}</v-chip>
        </template>
        <template v-slot:[`item.doc_typ_id`]="{ item }" :color="( item.doc_sta_id == 'GEN01' ) ? 'green' : 'grey'">
            <v-chip small outlined label :color="getCacheType('DOC', item.doc_typ_id).typ_color">{{ getCacheType('DOC', item.doc_typ_id).typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.doc_id`]="{ item }" :color="'grey'">
            <v-chip label outlined :title="item.trs_coddt" @click="downloadDocument(item.doc_id)">
                <v-icon left color='grey'>mdi-file-download</v-icon>
            </v-chip>
            <!-- <v-btn v-if="item.doc_xxx_id && item.doc_table_id == 'trs'" :href="'#/documents/site/' + item.doc_xxx_id" @click.stop small rounded outlined color="primary" dark class="ml-2">
                <v-icon>mdi-application-export</v-icon>
            </v-btn> -->
        </template>
        <template v-slot:[`item.doc_created_at`]="{ item }" :color="'grey'">
            <v-chip label outlined >{{ locDateFormat.formatLocale( item.doc_created_at, 'fr' ) }}</v-chip>
        </template>
        <template v-slot:[`item.trs_coddt`]="{ item }">
            <v-btn 
                v-if="item.doc_table_id == 'trs'" :href="'#/documents/site/' + item.doc_xxx_id" 
                small rounded outlined color="primary" dark>
                {{ (!item.trs_coddt || !item.trs_coddt.length) ? item.trs_mc2_code : item.trs_coddt }}
            </v-btn>
        </template>
        <template slot="no-data">
            <v-alert :value="true" color="info" class="mt-2" icon="mdi-information-outline">Aucun document</v-alert>
        </template>
    </v-data-table>
    </v-card>
    <v-card-actions class="">
        <v-container fluid>
            <v-row>
                <v-col cols="5">
                    <span v-show="pagination.totalFiltered">{{pagination.totalFiltered}} sur </span>
                    <span>{{pagination.totalItems}} résultats</span>
                </v-col>
                <v-col cols="2" class="text-right">
                    <v-text-field ref="pageinput" @focus="$event.target.select()" v-model.number="pagination.page" @input.native="changePage($event)" outlined dense label="Page"></v-text-field>
                </v-col>
                <v-col cols="4" class="text-right">
                    <v-pagination v-model="pagination.page" :length="pagination.pageCount" :total-visible="8" ></v-pagination>
                </v-col>
            </v-row>
        </v-container>
    </v-card-actions>
    <!-- <v-layout wrap>
        <v-flex xs6 class="text-left pl-8">
            <span>{{pagination.totalItems}} résultats</span>
        </v-flex>
        <v-flex xs6 class="text-right pr-0">
            <v-pagination v-model="pagination.page" :length="pagination.pageCount" :total-visible="7" ></v-pagination>
        </v-flex>
    </v-layout> -->
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale'
import { cacheGetters, cacheMutations } from "../store/cache.module"
import { docObj }       from '../mixins/doc_obj.js'

export default {
    name: 'document',
    props: ['filterView'],
    mixins: [ docObj ],
    data () {
        return {
            max25chars: (v) => v.length <= 25 || 'Trop de charactères',
            tmp: '',
            search: '',
            originName: { 
                'trs' : 'Site', 'eve' : 'Évènement', 'ukn' : ''
            },
            pagination: { page: 1, totalItems: 0, itemsPerPage: 12, totalPage: 1, limit: 0, pageCount: 0 },
            headers: [
                { text: 'Statut',  align: 'left', value: 'doc_sta_id'},
                { text: 'Type',    align: 'left', value: 'doc_typ_id'},
                { text: 'Nom',     align: 'left', value: 'doc_name'},
                { text: 'Création', value: 'doc_created_at' },
                { text: 'Action',  align: 'left', value: 'doc_id'},
                { text: 'Site',        align: 'left', value: 'trs_coddt'},
            ],
            headFilter: { doc_typ_id: [] },
            items: [],
            locDateFormat: dateLocale, // Format de date
            enableAdd: true,
            modalEvent : false,
            genFilter: true,
            pageImputDelai: undefined
        }
    },
    computed: {
        pages () {
            if (this.pagination.totalItems == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
        },
    },
    watch: {
        filterView: function(){
            if( !this.filterView ){
                this.getDocument()
            }
        },
        genFilter: function(){
            this.filterDocument.withtrackingsheet = this.genFilter
            this.getDocument()
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('documents')
        this.getDocument()
    },
    methods: {
        getDocument(options){
            options = options || ''
            options += '&extended=withtrackingsheet'
            this.items = []
            this.pagination.page = 1
            this.getDocumentIndex(1, false, '', options).then( () => {
                if( this.metaDocument.pagination.total !== undefined && this.metaDocument.pagination.total !== '0' ){
                    this.pagination.totalItems  = parseInt(this.metaDocument.pagination.total)
                } else {
                    this.pagination.totalItems = 1
                }
                this.items = this.listDocument
            })
        },
        saveVersion(){
                this.getEvent()
                this.enableAdd = true
        },
        openSite(trs_id){
            this.$router.push({ path: '/trackingsheet/site/' + trs_id })
        },
        downloadSite(){
            this.$root.$toast({ color: 'success', text: 'Fonction non disponible !' })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
            //this.pagination.page  = (index - 1)
            this.getDocument()
        },
        downloadDocument(doc_id){
            this.$http.customRequest({ method: 'get', url: 'documents/' + doc_id + '/download', responseType: 'blob' } )
            .then( (response) => {
                let filename      = 'd.pdf'
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(response.headers['content-disposition']);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
                if( response.status == 204 ){
                    this.$root.$toast({ color: 'warning', text: 'Ce fichier n\'existe pas' })
                } else {
                    this.$download(response.data, filename, response.headers['content-type'])
                }
            }).catch( (error) => {
                this.$root.$toast({ color: 'error', text: error })
            })
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('documents', this.search)
        },
        updateHeaderFilter(filter, value){
            let options = ''
            let index   = this.headFilter[filter].indexOf( value )
            if( index > -1 ){
                this.headFilter[filter].splice(index, 1)
            } else {
                this.headFilter[filter].push(value)
            }
            options = this.headFilter[filter].join(',')
            if( options.length > 1 ){
                this.getDocument( '&doc_typ_id=' + options )
            } else {
                this.getDocument()
            }
        },
        changePage(e){
            clearTimeout( this.pageImputDelai )
            this.pageImputDelai = setTimeout( () => {
                e.target.select()
            }, 700)
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        
    }
}
</script>

<style lang="css">

</style>